import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'components/ui/dialog';
import { Grouped, Overlay, Title } from './styles';
import { ColumnsDropdown } from './ColumnDropdown';
import { useState } from 'react';
import { billingItemsColumns } from './ColumnDropdown/utils';
import { Button } from 'components/ui/button';
import { isEmail } from 'class-validator';
import { EmailTagInput } from 'components/EmailTagInput';
import { SubmitButton } from '../PaymentDialog/style';
import useTheme from 'hooks/useTheme';
import { LoadingOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { EXPORT } from 'app/graphql';
import { ExportColumns } from 'app/graphql/generated/admin/graphql';
import { toast } from 'sonner';

type Props = {
  startDate: string;
  endDate: string;
};

export const ExportBillingItems = ({ startDate, endDate }: Props) => {
  const [emails, setEmails] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState<ExportColumns[]>(
    Object.keys(billingItemsColumns) as ExportColumns[],
  );
  const { theme } = useTheme();

  const handleSetSelectedColumns = (columns: ExportColumns[]) => {
    setSelectedColumns(columns);
  };

  const [exportBillingItems] = useMutation(EXPORT, {
    context: { clientName: 'admin' },
  });

  const handleExportSubmit = async () => {
    if (selectedColumns.length === 0) {
      toast.error('Please select at least one column to export', {
        id: 'export-billing-period-failed',
      });
    }
    setIsSubmitting(true);
    try {
      await exportBillingItems({
        variables: {
          input: {
            columns: selectedColumns,
            startDate,
            endDate,
            additionalRecipients: emails,
          },
        },
      });
      setIsSubmitting(false);
      setOpen(false);
      setEmails([]);
      setSelectedColumns(Object.keys(billingItemsColumns) as ExportColumns[]);
      toast.success('Request to export billing period successful', {
        id: 'export-billing-period-success',
      });
    } catch (error) {
      console.error(error);
      toast.error('Error requesting export for billing period', {
        id: 'export-billing-period-failed',
      });
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen} modal={false}>
      <DialogTrigger>
        <Button variant="default" size="lg" className="ml-auto hidden h-8 lg:flex">
          Export
        </Button>
      </DialogTrigger>
      {open && <Overlay className="inset-0 bg-black/80" />}
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Export billing period</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          An email with a download link will be sent to the email on this account
        </DialogDescription>
        <Grouped>
          <Title>Columns</Title>
          <ColumnsDropdown
            onColumnChange={handleSetSelectedColumns}
            selectedColumns={selectedColumns}
          />
        </Grouped>

        <Grouped>
          <Title>Additional Recipients</Title>
          <EmailTagInput
            emails={emails}
            setEmails={setEmails}
            disabled={isSubmitting}
            readOnly={isSubmitting}
            addTagsOnBlur={true}
            inputProps={{ inputMode: 'email' }}
            placeholder="Add Email"
            styleClasses={{
              input: 'w-full sm:max-w-[350px] shadow-none',
              tag: {
                body: 'bg-[rgba(78,213,88,35%)] text-gray-950 border-0 hover:bg-[rgba(78,213,88,35%)]',
              },
              tagList: { container: 'flex-wrap' },
            }}
            variant="primary"
            validateTag={(tag: string) => {
              return isEmail(tag);
            }}
          />
        </Grouped>
        <DialogFooter>
          <SubmitButton
            disabled={isSubmitting || selectedColumns.length === 0}
            onClick={handleExportSubmit}
            thememode={theme}
          >
            {isSubmitting ? <LoadingOutlined /> : 'Export'}
          </SubmitButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
