import { ExportColumns } from 'app/graphql/generated/admin/graphql';

export type ColumnMap = { [key in ExportColumns]: string };

export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export const billingItemsColumns: ColumnMap = {
  DATE: 'Date',
  ORDER_ID: 'Order Id',
  ITEM_NAMES: 'Item Names',
  CURRENCY: 'Currency',
  SUBTOTAL: 'Subtotal',
  SHIPPING: 'Shipping',
  MARGIN: 'Margin',
  DISCOUNT: 'Discount',
  TAX: 'Tax',
  TOTAL: 'Total',
  REFUND: 'Refund Total',
  PLATFORM_FEE: 'Platform Fee',
  FULFILLMENT_FEE: 'Fulfillment Fee',
  STORE_NAME: 'Store Name',
  STORE_URL: 'Store URL',
  THANK_YOU_PAGE: 'Thank you page',
  COMMISSION_STATUS: 'Commission Status',
  COMMISSION_TOTAL: 'Commission Total',
  COMMISSION_RYE: 'Commission Rye',
  COMMISSION_DEV: 'Commission Dev',
  COMMISSION_PAYER: 'Commission Payer',
  DISCOUNT_CODES: 'Discount Codes',
};
