import { graphQLClient } from '../client';
import { graphql } from '../generated/admin';
import { UpdateUserInput } from '../generated/admin/graphql';

export const ATTACH_PAYMENT_METHOD = graphql(`
  mutation attachPaymentMethod($input: AttachPaymentMethodInput!) {
    attachPaymentMethod(input: $input) {
      stripeConfig {
        customerId
        paymentMethod {
          ... on PaymentMethodCard {
            last4
            brand
            expMonth
            expYear
          }
          ... on PaymentMethodACH {
            last4AccountNumber
            bank
          }
        }
      }
    }
  }
`);

export const UPDATE_BILLING_NOTIFICATIONS = graphql(`
  mutation updateBillingNotifications($input: UpdateBillingNotificationsInput!) {
    updateBillingNotifications(input: $input) {
      settings {
        emails
      }
      error
    }
  }
`);

export const attachPaymentMethod = (id: string, firstName: string, lastName: string) => {
  return {
    input: {
      id,
      billingDetails: {
        firstName,
        lastName,
      },
    },
  };
};

export const SAVE_WEBHOOK_URL = graphql(`
  mutation saveWebhookURL($input: SaveWebhookURLInput!) {
    saveWebhookURL(input: $input) {
      url
      verified
    }
  }
`);

export const MERCHANTS = graphql(`
  query merchantsByDeveloper {
    merchantsByDeveloper {
      merchants {
        commissionRate
        commissionType
        domain
        logo
        name
      }
    }
  }
`);

export const BILLING_ITEMS = graphql(`
  query BillingItems($pagination: BillingItemsPaginationInput, $filters: BillingFiltersInput) {
    billingItems(pagination: $pagination, filters: $filters) {
      edges {
        node {
          createdAt
          id
          orderId
          amount {
            currency
            value
          }
          type
          payer
          receiver
          billingSetting {
            developerCommissionShare
            merchantCommissionRate
            volumeTiers {
              volume
              fee
            }
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        totalPages
      }
    }
  }
`);

export const INVOICES = graphql(`
  query invoices($filters: InvoiceFiltersInput) {
    invoices(filters: $filters) {
      edges {
        cursor
        node {
          createdAt
          amount {
            value
            currency
          }
          billingPeriod {
            startDate
            endDate
          }
          dueDate
          status
          items {
            description
            amount {
              value
              currency
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`);

export const ADMIN_ORDER_BY_ID = graphql(`
  query OrderById($id: ID!) {
    orderById(id: $id) {
      marketplaceOrderIds
      breakdown {
        subtotalCents
        totalCents
        taxCents
        discountCents
        marginCents
        currency
        shippingCents
        refundCents
      }
      marketplace
      commission {
        rate
        status
      }
      products {
        productId
        quantity
        ... on ShopifyProduct {
          variantId
        }
      }
    }
  }
`);

export const CREATE_DEVELOPER_TOKEN = graphql(`
  mutation createDeveloperToken($input: CreateDeveloperTokenInput!) {
    createDeveloperToken(input: $input) {
      token
      error
    }
  }
`);

export const DEVELOPERS = graphql(`
  query developers {
    developers {
      developers {
        id
        email
        displayName
        photoURL
      }
      error
    }
  }
`);

export const EXPORT = graphql(`
  mutation exportBillingItems($input: ExportBillingItemsInput!) {
    exportBillingItems(input: $input) {
      success
      error
    }
  }
`);

export const ADMIN_UPDATE_USER = graphql(`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      data {
        user {
          firstName
          lastName
          displayName
          apiKey
          adminToken
          authProvider
          email
          id
          webhookURL
          webhookURLVerified
          marginAmount
          marginIsPercent
          photoURL
          isApprovedForDirectCheckout
          spreedly {
            envToken
            envSecret
          }
          amazonBusinessConfig {
            businessType
            groupId
            email
            pendingGroupAccess
            token {
              type
              status
            }
          }
          website
          inventoryRequestLimits {
            uniqueStoreRequests
            uniqueProductRequests
          }
          jwtValidationKey
          hmacSecretKey
        }
        billingPeriodSettings {
          ... on BillingPerPeriod {
            gracePeriodDays
            billingPeriodDays
            billingPeriodStartDay
          }
          ... on BillingPerGMV {
            amount
            currency
            gracePeriodDays
          }
          ... on BillingPrePayment {
            amount
            currency
            minBalance
            gracePeriodDays
          }
        }
        stripeConfig {
          customerId
          paymentMethod {
            ... on PaymentMethodCard {
              last4
              brand
              expMonth
              expYear
            }
            ... on PaymentMethodACH {
              last4AccountNumber
              bank
            }
          }
        }
        notificationSettings {
          billingNotifications {
            emails
          }
        }
      }
      error
    }
  }
`);

export const ADMIN_USER = graphql(`
  query user {
    user {
      data {
        user {
          firstName
          lastName
          displayName
          apiKey
          adminToken
          authProvider
          email
          id
          webhookURL
          webhookURLVerified
          marginAmount
          marginIsPercent
          photoURL
          isApprovedForDirectCheckout
          spreedly {
            envToken
            envSecret
          }
          amazonBusinessConfig {
            businessType
            groupId
            email
            pendingGroupAccess
            token {
              type
              status
            }
          }
          website
          inventoryRequestLimits {
            uniqueStoreRequests
            uniqueProductRequests
          }
          jwtValidationKey
          hmacSecretKey
        }
        billingPeriodSettings {
          ... on BillingPerPeriod {
            gracePeriodDays
            billingPeriodDays
            billingPeriodStartDay
          }
          ... on BillingPerGMV {
            amount
            currency
            gracePeriodDays
          }
          ... on BillingPrePayment {
            amount
            currency
            minBalance
            gracePeriodDays
          }
        }
        stripeConfig {
          customerId
          paymentMethod {
            ... on PaymentMethodCard {
              last4
              brand
              expMonth
              expYear
            }
            ... on PaymentMethodACH {
              last4AccountNumber
              bank
            }
          }
        }
        notificationSettings {
          billingNotifications {
            emails
          }
        }
      }
      error
    }
  }
`);

export const getUser = async (apiKey: string | undefined, userToken: string) => {
  graphQLClient.set(apiKey, userToken);
  return graphQLClient.get().query({
    query: ADMIN_USER,
    context: {
      clientName: 'admin',
    },
  });
};

export const getDevelopers = async (apiKey: string | undefined, userToken: string) => {
  graphQLClient.set(apiKey, userToken);
  return graphQLClient.get().query({
    query: DEVELOPERS,
    context: {
      clientName: 'admin',
    },
  });
};

export const updateAdminUser = async (
  user: UpdateUserInput,
  apiKey: string | undefined,
  userToken: string,
) => {
  graphQLClient.set(apiKey, userToken);
  return graphQLClient.get().mutate({
    mutation: ADMIN_UPDATE_USER,
    context: {
      clientName: 'admin',
    },
    variables: {
      input: {
        firstName: user.firstName,
        lastName: user.lastName,
        displayName: user.displayName,
        website: user.website,
        email: user.email,
      },
    },
  });
};

export const createDeveloperToken = async ({
  developerId,
  apiKey,
  userToken,
  readOnly,
}: {
  developerId: string;
  apiKey: string | undefined | null;
  userToken: string | undefined | null;
  readOnly?: boolean;
}) => {
  if (apiKey || userToken) {
    graphQLClient.set(apiKey, userToken);
  }
  return graphQLClient.get().mutate({
    mutation: CREATE_DEVELOPER_TOKEN,
    context: {
      clientName: 'admin',
    },
    variables: {
      input: { developerId, readOnly },
    },
  });
};
