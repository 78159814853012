import { billingItemsColumns } from './utils';
import React from 'react';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import { ScrollArea, ScrollBar } from 'components/ui/scrolll-area';
import { ExportColumns } from 'app/graphql/generated/admin/graphql';

type ColumnsDropdownProps = {
  onColumnChange: (columns: ExportColumns[]) => void; // Changed to accept an array
  selectedColumns: ExportColumns[];
};

export const ColumnsDropdown = ({ onColumnChange, selectedColumns }: ColumnsDropdownProps) => {
  const selectedValues = new Set(selectedColumns);
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedValuesSize, setSelectedValuesSize] = React.useState(selectedValues.size);

  const handleCheckedChange = (column: ExportColumns) => (checked: boolean) => {
    const newColumns = new Set(selectedColumns);
    if (checked) newColumns.add(column);
    else newColumns.delete(column);
    onColumnChange(Array.from(newColumns));
  };

  const selectAllColumns = () => {
    const newColumns = Object.entries(billingItemsColumns).map(([key]) => key) as ExportColumns[];
    onColumnChange(newColumns);
  };

  const handleCloseDropdown = (open: boolean) => {
    if (open === false && selectedValuesSize !== selectedColumns.length) {
      setSelectedValuesSize(selectedColumns.length);
    } else setIsOpen(() => open);
  };

  const getSelectedLabel = () => {
    if (selectedColumns.length === 0) return 'None selected';
    if (selectedColumns.length === Object.keys(billingItemsColumns).length)
      return `All (${selectedColumns.length})`;
    return `${selectedColumns.length} selected`;
  };

  return (
    <DropdownMenu open={isOpen} onOpenChange={handleCloseDropdown} modal={false}>
      <DropdownMenuTrigger asChild>
        <div className="grid gap-1">
          <div className="w-[125px] cursor-pointer rounded-sm border border-solid border-[#0000001A] p-1 text-center text-[16px] font-[300]">
            {getSelectedLabel()}
          </div>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" className="h-[250px] w-[250px]">
        <DropdownMenuLabel className="text-md font-semi-bold" onClick={selectAllColumns}>
          Toggle columns
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <ScrollArea style={{ height: 'calc(100% - 41px - 0.5rem)' }}>
          <div>
            {Object.entries(billingItemsColumns).map(([key, column], index) => {
              return (
                <DropdownMenuCheckboxItem
                  key={column + index}
                  className="pointer-events-auto capitalize"
                  checked={selectedValues.has(key as ExportColumns)}
                  onCheckedChange={handleCheckedChange(key as ExportColumns)}
                >
                  {column}
                </DropdownMenuCheckboxItem>
              );
            })}
          </div>
          <ScrollBar orientation="vertical" />
        </ScrollArea>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
